.login {
  padding-top: calc((100% - 700px) / 10 );
  background-image: -webkit-linear-gradient(260deg,#F7B041 30%,rgba(251,167,66,.6) 140%);
  height: 100%;
  min-height: 100vh;
  overflow-y: hidden;
}

.login .login-box {
  background:#ffffffee;
  background-image: -webkit-linear-gradient(180degree, #ffffffee 20%, #ffffff55 100%);
  border-radius: 15px;
  margin: 0 auto;
  min-height: 500px;
  width: calc(100% / 1.5);
  -webkit-box-shadow: -1px 2px 50px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 2px 50px -1px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 2px 50px -1px rgba(0, 0, 0, 0.15);
}

.login .login-left-side {
  width: 100%;
  height: 100%;
  text-align: center;
}

.login .description {
  margin: -40px 0 30px 0;
  font-weight: bold;
  letter-spacing: 2px;
}

.login .login-pic {
  width: 85%;
  margin: 90px 70px;
  opacity: 0.9;
}

.login .box {
  margin: 0 auto;
  padding: 0 50px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  min-height: 500px;
}

.login img {
  margin: 30px auto 20px;
  width: 140px;
}

.login .alert {
  display: inline-block;
  margin-bottom: 24px;
  text-align: left;
  width: 300px;
}

.login .center {
  text-align: center;
}

.login .container {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: left;
}

.login .form {
  width: 250px;
}

.login .form .button {
  margin: 0;
  width: 100%;
}

.login .title {
  font-size: 17px;
  color: #F7B041;
  font-weight: 700;
  padding: 0px 0px 20px;
}

.login .logo {
  height: auto;
  width: 220px;
  padding: 20px 0px 0px;
}

.login .copyright {
  padding: 7px 20px;
  font-size: 8.5pt;
  color: #f4f4f4;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.login .app-logo {
  width: 100px;
  height: 33.333px;
  margin: 0px 1px -4px 1px;
  /* background-color: white; */
  border-radius: 6px;
  vertical-align: baseline;
}

@media screen and (max-width: 480px) {
  .login .box {
    margin: 0 auto;
    padding: 0 30px;
    border-radius: 15px;
  }

  .login .login-box {
    margin-top: 30px;
    width: calc(100% / 1.15);
  }

  .login .login-left-side {
    display: none;
  }
}

@media screen and (max-width: 400px),
(max-width: 480px) and (max-height: 680px) {
  .login {
    background-color: #fff;
    padding: 0;
  }

  .login .login-box {
    background: none;
    min-width: 240px;
    width: 320px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .login .logo {
    margin: 40px;
    width: 240px;
  }
}
